<template>
  <div v-bind:key="refreshKey">

    <b-table responsive
             v-bind:items="clientStatuses"
             v-bind:fields="fields"
             no-local-sorting
             v-bind:sort-by.sync="sort.field"
             v-bind:sort-desc.sync="sortIsDesc"
             striped
             hover
             @sort-changed="onSortChanged"
             @row-clicked="onRowClicked"
             style="white-space: nowrap; padding-bottom: 30px"
    >


      <template #cell(status)="data">
        <span class="font-weight-bold">{{ data.value }}</span>
      </template>

      <template #cell(active)="data">
        <span class="font-weight-bold" v-if="data.value!=null && data.value" style="color: darkgreen">{{ $t('yes') }}</span>
        <span class="font-weight-bold" v-else style="color: darkred">{{ $t('no') }}</span>
      </template>


      <template #cell(color)="data">
        <b-badge class="font-weight-bold color-badge" :style="{ backgroundColor: data.value }"></b-badge>
      </template>


<!--
      <template #cell(actions)="row">
        <b-button size="sm" class="btn btn-success" @click="openModal">
          <i class="fas fa-pencil-alt"></i>
        </b-button>
      </template>
-->


    </b-table>

    <!--{{pagination}}-->
    <div class="mt-2 text-center">
      <div class="d-inline-block">
        <b-pagination
            v-model="pagination.page"
            v-bind:total-rows="pagination.total"
            v-bind:per-page="pagination.size"
            @page-click="onPageClick"
            align="center"
        ></b-pagination>
      </div>
      <div class="d-inline-block">
        <b-select class="d-inline-block" style="width: 70px" size="sm" v-model="pagination.size"  @change="refresh">
          <b-select-option v-bind:value="10">10</b-select-option>
          <b-select-option v-bind:value="25">25</b-select-option>
          <b-select-option v-bind:value="50">50</b-select-option>
          <b-select-option v-bind:value="100">100</b-select-option>
        </b-select>
        /{{ pagination.total }} {{ $t('columns.counterNames.clientStatuses') }}

      </div>

    </div>
    <client-clientStatus-modal ref="clientStatus-modal"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import mixinTable from '@/components/mixin/mixinTable';
import mixinNotifications from '@/components/mixin/mixinNotifications';
import mixinBase from '@/components/mixin/mixinBase';
import ClientClientStatusModal from '@/components/modal/clientStatusModal.vue';

export default {
  name: 'ClientStatusTable',
  components: {
    ClientClientStatusModal,
  },
  mixins: [mixinBase, mixinTable, mixinNotifications],
  data: () => ({
    clientStatuses: [],
    comment: '',
    isClientStatusModalVisible: false,
    title: '',
    showModal: false,
    selectedItem: {},
  }),
  props: {

    filters: {
      default: () => ({})
    },
    columns: {
      default: () => ([])
    },
    lazy: {
      default: () => false
    },
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.pagination.page = 1;
        this.refresh();
      }
    }
  },
  created() {
    this.setPagination(1, 0, 10);
    this.refresh();

  },
  computed: {
    ...mapGetters('data', ['getBusinessUnitNameById', 'getOrganizationNameById']),
    getStatusColor() {
      return 'Primary';
    },
    fields() {
      let $this = this;

      let fields = (this.columns || []).map(column => {
        let label = column.key === 'clientId' ? $this.$t('columns.client') : $this.$t(`columns.${column.key}`);
        return {
          key: column.key,
          label: label,
          sortable: true,
          active: column.enable,
        };
      });

      fields = fields.filter(field => !field.key.startsWith('id'));
     /*  fields.push({
        key: 'actions',
        label: 'Actions',
        class: 'text-center',
        sortable: false,
        active: true,
      }); */

      return fields.filter(f => f.active === true);
    },

  },
  methods: {
    ...mapActions('clientStatus', ['getAllClientStatus', 'handleEvent', 'updateClientStatus']),
    openModal() {
      this.$refs['clientStatus-modal'].showModal(this.selectedItem.id);
    },

    closeModal() {
      this.showModal = false;
    },

    async refresh() {
      let $this = this;
      let loadingToken = this.setLoading();
      let sort = { ...$this.sort };
      if (sort.field == null) {
        sort.field = 'creationDate';
        sort.direction = 'ASC';
      }

      console.log('refresh clientStatus filter: ', this.filters);
      // console.log("refresh()", {  pagination: this.pagination, sort})
      const result = await this.getAllClientStatus(
          {
            pagination: this.pagination,
            sort,
            filter: this.filters,
          }
      )
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.clientStatus') }), $this.getErrorDescription(err));
            $this.setLoaded(loadingToken);
          });
      this.clientStatuses = result.data;
      this.setMetaDataFromResult(result);
      this.setLoaded(loadingToken);
    }
  }
};
</script>

<style scoped>
.custom-modal {
  min-width: 500px; /* Adjust the width as needed */
}

.btn-container .btn {
  margin-right: 2px;
}

.color-badge {
  display: inline-block;
  width: 15rem;
  height: 1rem;
}
</style>
