<template>
  <div>
    <!--Breadcrumb button-->
    <breadcrumb-button>
      <template #beforeDropdown>
        <b-button variant="success" class="btn-icon" v-b-tooltip v-bind:title="$t('operations.add')" @click="onClientStatusSelected(null)">
          <i class="fa-solid fa-plus" ></i>
        </b-button>
      </template>
      <template #items>
        <b-dropdown-item @click="openColumnsModal">
          <feather-icon
              icon="CheckSquareIcon"
              size="16"
          />
          <span class="align-middle ml-50">{{ $t('columns.self') }}</span>
        </b-dropdown-item>
      </template>

    </breadcrumb-button>
    <!--Filters-->
    <filter-card v-model="filter" v-bind:fields="filterFields" search/>
    <!--Table Card-->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>{{ $t('menu.clientStatuses') }}</h5>
      </b-card-header>
      <b-card-body>
        <clientStatus-table ref="clientStatusTable" v-bind:filters="filter" v-bind:columns="columns" :has-selection="false"
                        @selected="onClientStatusSelected" :lazy="true"/>
      </b-card-body>
    </b-card>
    <!--Modal-->
    <columns-modal ref="columns-modal" column-key="clientStatus" v-model="columns"
                   v-bind:columns-names="columnsNames"
    />
    <clientStatus-modal ref="clientStatus-modal"/>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import BreadcrumbButton from '@/components/widget/breadcrumbButton';
import ClientStatusSearchRequest from '@/model/clientStatus/ClientStatusSearchRequest';
import ColumnsModal from '@/components/modal/columnsModal';
import ClientStatus from '@/model/clientStatus/ClientStatus';
import mixinPermissions from '@/components/mixin/mixinPermissions';
import mixinBase from '@/components/mixin/mixinBase';
import ClientStatusModal from '@/components/modal/clientStatusModal';
import FilterCard from '@/components/widget/FilterCard';
import ClientStatusFilterFields from '@/model/filter/ClientStatusFilterFields';
import ClientStatusTable from '@/components/tables/ClientStatusTable';

export default {
  name: 'ClientStatusView',
  components: {
    ClientStatusTable,
    FilterCard,
    ClientStatusModal,
    ColumnsModal,
    BreadcrumbButton,
  },
  mixins: [mixinBase, mixinPermissions],
  data() {
    return {
      columnsNames: Object.keys(ClientStatus()),
      columns: [],
      filter: ClientStatusSearchRequest(),
      filterLoaded: false,
      filterFields: null,
    };
  },
  watch: { },
  async created() {
    await this.refreshData();
    this.filterFields = ClientStatusFilterFields();
  },
  computed: {
    ...mapGetters('data', ['allOrganizations']),
  },
  methods: {
    ...mapActions('clientStatus', ['refreshData']),
    openColumnsModal() {
      this.$refs['columns-modal'].showModal();
    },
    onClientStatusSelected(selectedId) {
      // console.log("onClientStatusSelected", selectedId)
      this.$refs['clientStatus-modal'].showModal(selectedId);
    },
  }
};
</script>

<style>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #dfe5fb;
  border: none;
  color: #394066;
  text-transform: lowercase;
  font-variant: small-caps;
  height: 10px;
}
</style>
