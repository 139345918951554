<template>
  <div>
    <b-row>
      <b-col cols="12">
        <h4 class="text-primary text-center">
          <i class="fa-solid fa-clientStatus"></i>
          {{ $t('tabs.clientStatus.details') }}
        </h4>
      </b-col>


      <b-col cols="12" xl="12" class="mb-1">
        <label>{{ $t('columns.status') }}</label>
        <b-form-input :disabled="clientStatus.status==='Lead' ||clientStatus.status=== 'New'" v-model="clientStatus.status"/>
      </b-col>

      <b-col cols="12" xl="12" class="mb-1">
        <label>{{ $t('columns.color') }}</label>
        <v-select
            :disabled="clientStatus.status==='Lead'|| clientStatus.status==='New'"
            :clearable="false"
            :multiple="false"
            :options="colors"
            label="label"
            :reduce="(bu) => bu.value"
            v-model="clientStatus.color"
            :option-height="40"
        >
          <template #option="option">
<!--            <div :style="{ backgroundColor: option.value, height: '100%', padding: '10px', color: getTextColor(option.value) }">
              {{ option.label }}
            </div> -->
            <b-badge  :variant="option.value" :style="{ backgroundColor: option.value, width: '100%', padding: '10px', color: getTextColor(option.value) }">
              {{ option.label }}
            </b-badge>
          </template>
          <template #selected-option="selectedOption">
            <div class="rounded" :style="{ backgroundColor: selectedOption.value, padding: '6px', color: getTextColor(selectedOption.value) }">
              {{ selectedOption.label }}
            </div>
          </template>
        </v-select>
      </b-col>

    </b-row>
    <div class="text-center mt-2" style="position: absolute;bottom: 10px; width: 90%" >
      <b-button :disabled="clientStatus.status==='Lead'" block class="d-inline-block" size="sm" variant="primary" @click="save">{{$t('operations.save')}}</b-button>
    </div>
  </div>
</template>

<script>
import ClientStatus from '@/model/clientStatus/ClientStatus'
import mixinPermissions from '@/components/mixin/mixinPermissions'
import { mapActions, mapGetters } from 'vuex'
import mixinNotifications from '@/components/mixin/mixinNotifications'
import OrganizationSelectBox from '@/components/widget/select/OrganizationSelectBox'
import BusinessUnitSelectBox from '@/components/widget/select/BusinessUnitSelectBox'
import CountriesSelectBox from '@/components/widget/select/CountriesSelectBox'
import CalendarPicker from '@/components/widget/picker/calendarPicker'
import UserSelectBox from '@/components/widget/select/UserSelectBox';
const uniqueColors = [
  "#fa3e20",
  "#d6c508",
  "#da48ff",
  "#fec0cf",
  "#1cc245",
  "#1c73c4",
  "#ea967b",
  "#059b96",
  "#ff9f00",
  "#4786d3",
  "#ffbb00",
  "#d400ff"
];
export default {
  name: 'ClientStatusEditDetails',
  components: { UserSelectBox, CalendarPicker, CountriesSelectBox, BusinessUnitSelectBox, OrganizationSelectBox },
  mixins: [mixinPermissions, mixinNotifications],
  data: ()=>({
    clientStatus: ClientStatus(),

  }),
  props: {
    value: {
      required: true,
      default: () => ({})
    },
    newClientStatus: {
      type: Boolean,
      default: () => false
    }
  },
  watch:{
    value:{
      handler(){
        this.clientStatus = Object.assign(ClientStatus(), this.value)
      },
      immediate: true
    }
  },
  computed:{
   ...mapGetters('clientStatus', ['']),

    colors(){
      return uniqueColors.map((color,index) => {
        return { label: `Color ${index + 1}`, value: color };
      })
    }
  },
  methods:{
    ...mapActions('clientStatus', ['updateClientStatus','createClientStatus']),
    getTextColor(backgroundColor) {
      // A simple function to determine the best text color (black or white) based on the background color
      const color = backgroundColor.substring(1); // Remove the '#' character
      const rgb = parseInt(color, 16); // Convert hex to decimal
      const r = (rgb >> 16) & 0xff; // Extract red
      const g = (rgb >> 8) & 0xff; // Extract green
      const b = (rgb >> 0) & 0xff; // Extract blue
      const luma = 0.299 * r + 0.587 * g + 0.114 * b; // Calculate luma
      return luma > 128 ? 'black' : 'white'; // Return 'black' or 'white' based on luma
    },
    async save() {
      if (this.clientStatus.id == null) {
        await this.addClientStatus();
      } else {
        await this.modifyClientStatus();
      }
    },

    async addClientStatus() {
      let $this = this;
      await this.createClientStatus({
        clientStatus: this.clientStatus
      })
          .catch(err => {
            this.showErrorNotification(this.$t('globalMessages.fetchDataError', { system: this.$t('menu.clientStatus') }), err.response.data.error);
          });
      this.showSuccessNotification($this.$t('globalMessages.addDataSuccess', { system: $this.$t('menu.clientStatus') }));
      this.$emit('input', this.clientStatus);
      this.$emit('close');
      this.refreshTables();

    },
    async modifyClientStatus() {
      let $this = this;

      await this.updateClientStatus({
        clientStatus: this.clientStatus,
        id: this.clientStatus.id
      })
          .catch(err => {
            $this.showErrorNotification($this.$t('globalMessages.fetchDataError', { system: $this.$t('menu.clientStatus') }), err.response.data.error);
          });
      this.showSuccessNotification($this.$t('globalMessages.updateDataSuccess', { system: $this.$t('menu.clientStatus') }));
      this.$emit('input', this.clientStatus);
      this.$emit('close');
      this.refreshTables();

    },
  }
}
</script>

<style scoped>

</style>
