export default function (other) {
  other = other || {}
  return {
    id: other.id || null,
    status: other.status || null,
/*
    orgId: other.orgId || null,
*/
    color: other.color || null,
    active: other.active || null,
/*
    type: other.type || null,
*/
  }
}
